import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Box, Button, Dialog } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import ErrorLoadingData from 'Components/ErrorLoadingData'
import HubMethods from 'Consts/HubMethods'
import { useSignalRContext } from 'Context/SignalRContext'
import { useOrderDetailsQuery } from 'Hooks/orders'
import { queryClient } from 'Lib/react-query'
import { OrderUpdatedMessage } from 'Types/HubMessages'

import ForemanOrdersTable from '../ForemanOrdersTable'
import { getIsOrderCompletionImpossible } from '../utils'

type Props = {
  open: boolean
  onClose: () => void
  orderId: number
  orderName: string
}

const ForemanOrderDetailsDialog = ({ orderId, orderName, open, onClose }: Props) => {
  const { t } = useTranslation()
  const { data, isLoading, isError, refetch } = useOrderDetailsQuery(orderId, { enabled: open && !!orderId })
  const { hubConnection } = useSignalRContext()

  const isOrderCompletionImpossible = !!data && getIsOrderCompletionImpossible(data)

  useEffect(() => {
    const handleOrderUptated = (order: OrderUpdatedMessage) => {
      if (order.id === orderId) queryClient.setQueryData(['order-details', orderId], order)
    }

    hubConnection?.on(HubMethods.OrderUpdated, handleOrderUptated)

    return () => {
      hubConnection?.off(HubMethods.OrderUpdated, handleOrderUptated)
    }
  }, [hubConnection, orderId])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disablePortal
      fullScreen
      sx={{
        padding: 4,
        paddingTop: 2,
      }}
    >
      <DialogTitle onClose={onClose}>{orderName}</DialogTitle>
      <DialogContent>
        {isError ? (
          <Box display='flex' height='100%' alignItems='center' justifyContent='center'>
            <ErrorLoadingData onRetryClick={refetch} />
          </Box>
        ) : (
          <Box display='flex' flexDirection='column' height='100%' gap={3}>
            {isOrderCompletionImpossible && (
              <Alert severity='error'>
                {t('messages.addAtLeastOneActiveWorkstationToTheComponentsOfTheOrderThatCannotBeCompleted')}
              </Alert>
            )}
            <Box display='flex' flex={1} minHeight={0}>
              <ForemanOrdersTable data={data ? [data] : []} isLoading={isLoading} statuses={[]} defaultExpanded />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions actionsVariant='center'>
        <Button onClick={onClose}>{t('labels.close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ForemanOrderDetailsDialog
