import './index.css'
import './Lib/i18n'

import React from 'react'
import ReactDOM from 'react-dom'

import AuthManager from 'Auth/AuthManager'
import OpenedTabsMonitor from 'Auth/OpenedTabsMonitor'
import SessionMonitor from 'Auth/SessionMonitor'
import Analytics from 'Lib/Analytics'
import ErrorTracker from 'Lib/error-tracker'

import App from './App'

ErrorTracker.init()
Analytics.init()

OpenedTabsMonitor.init()
AuthManager.init()
SessionMonitor.init()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
