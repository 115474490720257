import WorkstationStates, { type WorkstationState } from 'Consts/WorkstationStates'
import { palette } from 'Theme/colors'

export const getColor = (type?: WorkstationState | null) => {
  switch (type) {
    case WorkstationStates.NOT_WORKING:
      return palette.status.notWorking.main
    case WorkstationStates.MICROIDLE:
      return palette.status.microidle.main
    case WorkstationStates.IDLE:
      return palette.status.idle.main
    case WorkstationStates.NORMAL_WORK:
      return palette.status.normalWork.main
    case WorkstationStates.FAILURE:
      return palette.status.failure.main
    case WorkstationStates.WORK_WITH_FAILURE:
      return palette.status.workWithFailure.main
    case WorkstationStates.RETOOL:
      return palette.status.retool.main
    default:
      return palette.status.idle.main
  }
}

export const getStatusColorContrastText = (type?: WorkstationState | null) => {
  switch (type) {
    case WorkstationStates.NOT_WORKING:
      return palette.status.notWorking.contrastText
    case WorkstationStates.MICROIDLE:
      return palette.status.microidle.contrastText
    case WorkstationStates.IDLE:
      return palette.status.idle.contrastText
    case WorkstationStates.NORMAL_WORK:
      return palette.status.normalWork.contrastText
    case WorkstationStates.FAILURE:
      return palette.status.failure.contrastText
    case WorkstationStates.WORK_WITH_FAILURE:
      return palette.status.workWithFailure.contrastText
    case WorkstationStates.RETOOL:
      return palette.status.retool.contrastText
    default:
      return palette.status.idle.contrastText
  }
}

export const getText = (type = '') => {
  switch (type) {
    case WorkstationStates.NOT_WORKING:
      return 'labels.notWorking'
    case WorkstationStates.MICROIDLE:
      return 'labels.microidle'
    case WorkstationStates.IDLE:
      return 'labels.idleStatus'
    case WorkstationStates.NORMAL_WORK:
      return 'labels.normalWork'
    case WorkstationStates.FAILURE:
      return 'labels.failure'
    case WorkstationStates.WORK_WITH_FAILURE:
      return 'labels.workWithFailure'
    case WorkstationStates.RETOOL:
      return 'labels.retool'
    default:
      return 'labels.idleStatus'
  }
}

export const getAdditionalText = (type = '') => {
  switch (type) {
    case WorkstationStates.FAILURE:
      return 'labels.machineProblem'
    case WorkstationStates.WORK_WITH_FAILURE:
      return 'labels.vulnerability'
    default:
      return ''
  }
}
