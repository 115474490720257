import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import HubMethods from 'Consts/HubMethods'
import { useDialogsContext } from 'Context/DialogsContext'
import { useMachineContext } from 'Context/MachineContext'
import { useServiceWorker } from 'Context/ServiceWorkerContext'
import { useSignalRContext } from 'Context/SignalRContext'
import { useUser as useUserContext } from 'Context/UserContext'
import paths from 'Routes/paths'
import type { RfidCardAppliedMessage } from 'Types'

import CardRemoved from './CardRemoved'
import UpgradeDialog from './UpgradeDialog'

const GlobalPopupsHandler = () => {
  const { user } = useUserContext()
  const { machine } = useMachineContext()
  const { hubConnection } = useSignalRContext()
  const {
    cardRemovedOpen,
    setCardRemovedOpen,
    removeCardDialogOpen,
    addMultiOperatorDialogOpen,
    replaceMultiOperatorDialogOpen,
    closeAll,
  } = useDialogsContext()
  const { newVersion, updateApplicationAndReloadPage } = useServiceWorker()
  const location = useLocation()
  const isLoginPage = [paths.rfidLogin, paths.login].includes(location.pathname)
  const machineId = machine?.id

  useEffect(() => {
    if (!machine?.active) {
      closeAll()
    }
  }, [machine])

  const enableRfidCardAppliedListener =
    !isLoginPage &&
    !removeCardDialogOpen &&
    !cardRemovedOpen &&
    !addMultiOperatorDialogOpen &&
    !replaceMultiOperatorDialogOpen

  useEffect(() => {
    const handleRfidCardApplied = ({ isValid, rfid, workstationId }: RfidCardAppliedMessage) => {
      if (isValid && user && user.rfid === rfid && machineId === workstationId) {
        setCardRemovedOpen(true)
      }
    }

    if (enableRfidCardAppliedListener) {
      hubConnection?.on(HubMethods.RfidCardApplied, handleRfidCardApplied)
    }

    return () => {
      if (enableRfidCardAppliedListener) {
        hubConnection?.off(HubMethods.RfidCardApplied, handleRfidCardApplied)
      }
    }
  }, [hubConnection, user, enableRfidCardAppliedListener, machineId])

  useEffect(() => {
    if (!user) {
      setCardRemovedOpen(false)
    }
  }, [user])

  return (
    <>
      {!removeCardDialogOpen && cardRemovedOpen && (
        <CardRemoved open={cardRemovedOpen} close={() => setCardRemovedOpen(false)} />
      )}
      {newVersion && <UpgradeDialog open={newVersion} onSubmit={() => updateApplicationAndReloadPage()} />}
    </>
  )
}

export default GlobalPopupsHandler
