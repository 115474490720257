import { ReasonDescriptionType, TranslationValuesDto } from 'Types'

type Result = { [key: string]: Record<string, unknown> | string }

export const convertTranslation = (data: TranslationValuesDto['keyValuePairs']) => {
  const convertedData = data.reduce((result, el) => {
    let resultRef: Result = result
    const { key, value } = el
    const keysArr = key.split('.')

    if (!value) {
      return result
    }

    keysArr.forEach((k, index) => {
      const isLast = index === keysArr.length - 1

      if (!resultRef[k] && !isLast) {
        resultRef[k] = {}
      }

      if (!resultRef[k] && isLast) {
        resultRef[k] = value
      }

      resultRef = resultRef[k] as Result
    })

    return result
  }, {})

  return convertedData
}

export const getCatalogPreviewTranslations = (
  reasonDescription: ReasonDescriptionType | null | undefined,
  languageCode: string,
) => {
  if (!reasonDescription || (!reasonDescription.description && !reasonDescription.translatedDescriptions)) {
    return undefined
  }

  const defaultDescription = Object.values(reasonDescription?.translatedDescriptions)[0] || ''

  const failureDescription =
    reasonDescription?.description ?? reasonDescription?.translatedDescriptions[languageCode] ?? defaultDescription

  return failureDescription
}
