import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  BarChart as BarChartIcon,
  Equalizer as EqualizerIcon,
  FlipToFront as FlipToFrontIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Inventory2Outlined as OrdersIcon,
  Notifications as NotificationsIcon,
  ShowChart as ProductivityChartIcon,
  StackedBarChart as StackedBarChartIcon,
  Traffic as TrafficIcon,
  ViewQuilt as ViewQuiltIcon,
} from '@mui/icons-material'

import HubMethods from 'Consts/HubMethods'
import { useNotificationContext } from 'Context/NotificationContext'
import { useSignalRContext } from 'Context/SignalRContext'
import paths from 'Routes/paths'
import { WorkstationStatusChangedMessage } from 'Types'

import PlanIcon from '../Icons/PlanIcon'
import YamazumiIcon from '../Icons/YamazumiIcon'
import NavLinks, { NavLinkItem } from '../NavLinks'

type Props = {
  collapsed: boolean
}

const ForemanNav = ({ collapsed }: Props) => {
  const { notificationsCount } = useNotificationContext()
  const [lineStatusNotification, setLineStatusNotification] = useState(false)
  const { t } = useTranslation()
  const { hubConnection } = useSignalRContext()
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === paths.foremanLineStatus) {
      setLineStatusNotification(false)
    }
  }, [pathname])

  useEffect(() => {
    const handleForemanWorkstationStatusChange = ({ productionLineId }: WorkstationStatusChangedMessage) => {
      if (pathname !== paths.foremanLineStatus && productionLineId) {
        setLineStatusNotification(true)
      }
    }

    hubConnection?.on(HubMethods.StartedNormalWork, handleForemanWorkstationStatusChange)
    hubConnection?.on(HubMethods.StartedFailure, handleForemanWorkstationStatusChange)
    hubConnection?.on(HubMethods.StartedWorkWithFailure, handleForemanWorkstationStatusChange)
    hubConnection?.on(HubMethods.StartedIdle, handleForemanWorkstationStatusChange)
    hubConnection?.on(HubMethods.IdleWasSetOnWorkstation, handleForemanWorkstationStatusChange)
    hubConnection?.on(HubMethods.MicroidleSet, handleForemanWorkstationStatusChange)
    hubConnection?.on(HubMethods.IdleEnded, handleForemanWorkstationStatusChange)
    hubConnection?.on(HubMethods.MicroidleEnded, handleForemanWorkstationStatusChange)

    return () => {
      hubConnection?.off(HubMethods.StartedNormalWork, handleForemanWorkstationStatusChange)
      hubConnection?.off(HubMethods.StartedFailure, handleForemanWorkstationStatusChange)
      hubConnection?.off(HubMethods.StartedWorkWithFailure, handleForemanWorkstationStatusChange)
      hubConnection?.off(HubMethods.StartedIdle, handleForemanWorkstationStatusChange)
      hubConnection?.off(HubMethods.IdleWasSetOnWorkstation, handleForemanWorkstationStatusChange)
      hubConnection?.off(HubMethods.MicroidleSet, handleForemanWorkstationStatusChange)
      hubConnection?.off(HubMethods.IdleEnded, handleForemanWorkstationStatusChange)
      hubConnection?.off(HubMethods.MicroidleEnded, handleForemanWorkstationStatusChange)
    }
  }, [hubConnection, pathname])

  const navItems: NavLinkItem[] = [
    {
      link: paths.foremanNotifications,
      label: t('labels.notifications'),
      Icon: NotificationsIcon,
      border: true,
      badge: notificationsCount,
    },
    {
      link: paths.foremanProductionFlow,
      label: t('labels.productionFlow'),
      Icon: PlanIcon,
    },
    {
      link: paths.foremanOrders,
      label: t('labels.orders'),
      Icon: OrdersIcon,
    },
    {
      link: paths.foremanOutput,
      label: t('labels.output'),
      Icon: StackedBarChartIcon,
    },
    {
      link: paths.foremanScrapsClassification,
      label: t('labels.scraps'),
      Icon: EqualizerIcon,
    },
    {
      link: paths.foremanComponentScrapsClassification,
      label: t('labels.componentScraps'),
      Icon: EqualizerIcon,
    },
    {
      link: paths.foremanIdleReasons,
      label: t('labels.idleReasons'),
      Icon: BarChartIcon,
    },
    {
      link: paths.foremanRetools,
      label: t('labels.retools'),
      Icon: BarChartIcon,
    },
    {
      link: paths.foremanFailureReasons,
      label: t('labels.failureReasons'),
      Icon: BarChartIcon,
    },
    {
      link: paths.foremanWorkWithFaiilureReasons,
      label: t('labels.workWithFailureReasons'),
      Icon: BarChartIcon,
    },
    {
      link: paths.foremanLineStatus,
      label: t('labels.lineStatus'),
      Icon: TrafficIcon,
      showNotification: lineStatusNotification,
    },
    {
      link: paths.foremanProductivity,
      label: t('labels.productivity'),
      Icon: ProductivityChartIcon,
    },
    {
      link: paths.foremanYamazumi,
      label: t('labels.yamazumi'),
      Icon: YamazumiIcon,
    },
    {
      link: paths.foremanPerformanceDrops,
      label: t('labels.performanceDrops'),
      Icon: BarChartIcon,
    },
    {
      link: paths.foremanStatusesTimelineChart,
      label: t('labels.statusesTimeline'),
      Icon: EqualizerIcon,
    },
    {
      link: paths.foremanOeeChart,
      label: t('labels.oee'),
      Icon: BarChartIcon,
    },
    {
      link: paths.foremanKanban,
      label: t('labels.maintenanceKanban'),
      Icon: FlipToFrontIcon,
    },
    {
      link: paths.foremanPDCA,
      label: t('labels.pdca'),
      Icon: ViewQuiltIcon,
    },
    {
      link: paths.foremanFiles,
      label: t('labels.files'),
      Icon: InsertDriveFileIcon,
    },
  ]

  return <NavLinks items={navItems} collapsed={collapsed} />
}

export default ForemanNav
