import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import EnforceRetoolFromOrderDialog from 'Features/orders/EnforceRetoolFromOrderDialog'

import { ApiAxiosInstance, apiClient } from 'Api/ApiClient'
import errors from 'Consts/Errors'
import { OrderExecutors } from 'Consts/executors'
import HubMethods from 'Consts/HubMethods'
import WorkstationStates, { type WorkstationState } from 'Consts/WorkstationStates'
import { useDialogsContext } from 'Context/DialogsContext'
import { useMachineContext } from 'Context/MachineContext'
import { useNavigationContext } from 'Context/NavigationContext'
import { useProductionContext } from 'Context/ProductionContext'
import { useSignalRContext } from 'Context/SignalRContext'
import { useUser as useUserContext } from 'Context/UserContext'
import paths from 'Routes/paths'
import { OrderComponentMachineCompletedMessage, OrderComponentMachineResumedMessage } from 'Types/HubMessages'

import { MultipleUnitsDialog } from '../WorkingWithFailureHandler/MultipleUnitsDialog'
import FailureStatusSelect from './FailureStatusSelect'
import FailureUnitsDialog from './FailureUnitsDialog'
import NetworkErrorDialog from './NetworkErrorDialog'
import PotentialNOKDialogHandler from './PotentialNOKDialogHandler'
import ProductionStartedWithoutIdleReason from './ProductionStartedWithoutIdleReason'

const OperatorPanelDialogsHandler = () => {
  const [selectedStatus, setSelectedStatus] = useState<WorkstationState | null>(null)
  const { user } = useUserContext()
  const { machine, setMachine, variant, order } = useMachineContext()
  const { status, enforceReasonsClassification } = useProductionContext()
  const [isEditingProductionUnitsCountAfterRetooling, setIsEditingProductionUnitsCountAfterRetooling] = useState(false)
  const [isUpdatingProductionUnitsCount, setIsUpdatingProductionUnitsCount] = useState(false)
  const { hubConnection } = useSignalRContext()
  const { setDisableNavigation } = useNavigationContext()
  const { t } = useTranslation()
  const history = useHistory()
  const {
    failureStatusSelectOpen,
    setFailureStatusSelectOpen,
    failureUnitsDialogOpen,
    setFailureUnitsDialogOpen,
    productionStartedWithoutReportingReasonDialogOpen,
    setProductionStartedWithoutReportingReasonDialogOpen,
    productionStartedWithoutReportingReasonDialogConfirmed,
    setProductionStartedWithoutReportingReasonDialogConfirmed,
    networkErrorDialogOpen,
    setNetworkDialogOpen,
    enforceRetoolFromOrderDialogOpen,
    setEnforceRetoolFromOrderDialogOpen,
  } = useDialogsContext()
  const machineId = machine?.id
  const selectedVariant = variant?.id
  const statusType = status?.type

  useEffect(() => {
    if (
      user &&
      enforceReasonsClassification &&
      (statusType === WorkstationStates.NORMAL_WORK || statusType === WorkstationStates.WORK_WITH_FAILURE)
    ) {
      setProductionStartedWithoutReportingReasonDialogOpen(true)
    }
  }, [enforceReasonsClassification, statusType])

  useEffect(() => {
    const networkErrorInterceptor = ApiAxiosInstance.interceptors.response.use(undefined, (error) => {
      if (error?.message === errors.networkError) {
        setNetworkDialogOpen(true)
      }
      throw error
    })

    return () => {
      ApiAxiosInstance.interceptors.response.eject(networkErrorInterceptor)
    }
  }, [])

  useEffect(() => {
    const handleFailureBunchProducedMessage = () => {
      if (!failureUnitsDialogOpen) {
        setFailureStatusSelectOpen(true)
      }
    }

    hubConnection?.on(HubMethods.FailureBunchProduced, handleFailureBunchProducedMessage)

    return () => {
      hubConnection?.off(HubMethods.FailureBunchProduced, handleFailureBunchProducedMessage)
    }
  }, [hubConnection, failureUnitsDialogOpen])

  useEffect(() => {
    const handleWorkstationRetooledMessage = () => {
      if (
        !machine?.id ||
        status?.type !== WorkstationStates.WORK_WITH_FAILURE ||
        isEditingProductionUnitsCountAfterRetooling ||
        !selectedVariant
      ) {
        return
      }

      apiClient.getWorkstationStatus(machine.id).then(({ data: workstationStatus }) => {
        const actualCount = workstationStatus.actualUnitProductionCount
        const shouldSetProductionUnitsCount = actualCount && actualCount > 1

        if (shouldSetProductionUnitsCount) {
          setIsEditingProductionUnitsCountAfterRetooling(true)
        }
      })
    }

    hubConnection?.on(HubMethods.WorkstationRetooled, handleWorkstationRetooledMessage)

    return () => hubConnection?.off(HubMethods.WorkstationRetooled, handleWorkstationRetooledMessage)
  }, [hubConnection, machine, selectedVariant, status])

  useEffect(() => {
    const handleOrderComponentMachineCompleted = ({
      orderComponentId,
      finishExecutor,
    }: OrderComponentMachineCompletedMessage) => {
      if (order?.componentId === orderComponentId && finishExecutor !== OrderExecutors.OPERATOR) {
        setEnforceRetoolFromOrderDialogOpen(true)
      }
    }
    const handleOrderComponentMachineResumed = (message: OrderComponentMachineResumedMessage) => {
      if (order?.componentId === message.orderComponentId) {
        setEnforceRetoolFromOrderDialogOpen(false)
      }
    }

    hubConnection?.on(HubMethods.OrderComponentMachineCompleted, handleOrderComponentMachineCompleted)
    hubConnection?.on(HubMethods.OrderComponentMachineResumed, handleOrderComponentMachineResumed)

    return () => {
      hubConnection?.off(HubMethods.OrderComponentMachineCompleted, handleOrderComponentMachineCompleted)
      hubConnection?.off(HubMethods.OrderComponentMachineResumed, handleOrderComponentMachineResumed)
    }
  }, [hubConnection, order])

  return (
    <>
      {enforceRetoolFromOrderDialogOpen && (
        <EnforceRetoolFromOrderDialog
          open={enforceRetoolFromOrderDialogOpen}
          onCancel={() => setEnforceRetoolFromOrderDialogOpen(false)}
          onConfirm={() => {
            setEnforceRetoolFromOrderDialogOpen(false)
            history.push(paths.operatorRetool)
          }}
        />
      )}
      {failureStatusSelectOpen && (
        <FailureStatusSelect
          setSelectedStatus={setSelectedStatus}
          openFailureUnitsDialog={() => setFailureUnitsDialogOpen(true)}
          open={failureStatusSelectOpen}
          close={() => setFailureStatusSelectOpen(false)}
        />
      )}
      {failureUnitsDialogOpen && (
        <FailureUnitsDialog
          selectedStatus={selectedStatus}
          openFailureStatusSelect={() => setFailureStatusSelectOpen(true)}
          open={failureUnitsDialogOpen}
          onClose={() => setFailureUnitsDialogOpen(false)}
        />
      )}
      {machineId && isEditingProductionUnitsCountAfterRetooling && (
        <MultipleUnitsDialog
          open={isEditingProductionUnitsCountAfterRetooling}
          onAccept={(count) => {
            setIsUpdatingProductionUnitsCount(true)
            apiClient
              .putWorkstationProducedUnitsCount(machineId, count)
              .then(() => {
                setMachine((prevMachine) => {
                  if (prevMachine) {
                    return {
                      ...prevMachine,
                      actualUnitProductionCount: count,
                    }
                  }

                  return prevMachine
                })
                setIsEditingProductionUnitsCountAfterRetooling(false)
              })
              .finally(() => setIsUpdatingProductionUnitsCount(false))
          }}
          title={t('messages.machineRetooledInSlowedDownMode')}
          actionDescription={t('messages.indicateNumberOfItemsToProduceOnThisVariant')}
          actualUnitProductionCount={machine?.defaultUnitProductionCount || 0}
          buttonDisabled={isUpdatingProductionUnitsCount}
        />
      )}
      {productionStartedWithoutReportingReasonDialogOpen && !productionStartedWithoutReportingReasonDialogConfirmed && (
        <ProductionStartedWithoutIdleReason
          open={productionStartedWithoutReportingReasonDialogOpen}
          onConfirm={() => {
            setProductionStartedWithoutReportingReasonDialogOpen(false)
            setProductionStartedWithoutReportingReasonDialogConfirmed(true)
            if (history.location.pathname !== paths.operatorEnforcedReport) {
              setDisableNavigation(true)
              history.push(paths.operatorEnforcedReport, { prevPath: history.location.pathname })
            }
          }}
        />
      )}
      {networkErrorDialogOpen && (
        <NetworkErrorDialog open={networkErrorDialogOpen} onClose={() => setNetworkDialogOpen(false)} />
      )}
      <PotentialNOKDialogHandler />
    </>
  )
}

export default OperatorPanelDialogsHandler
