import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dashboard as DashboardIcon } from '@mui/icons-material'

import paths from 'Routes/paths'

import NavLinks, { NavLinkItem } from '../NavLinks'

type Props = {
  collapsed: boolean
}

const ForemanDashboardLinks = ({ collapsed }: Props) => {
  const { t } = useTranslation()

  const navItems: NavLinkItem[] = [
    {
      link: paths.foremanDashboard,
      Icon: DashboardIcon,
      label: t('labels.dashboard'),
    },
  ]

  return <NavLinks items={navItems} collapsed={collapsed} />
}

export default ForemanDashboardLinks
