import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Dialog } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import { useMachineContext } from 'Context/MachineContext'
import { Bold } from 'Styles/utils'

type Props = {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
}

const EnforceRetoolFromOrderDialog = ({ open, onCancel, onConfirm }: Props) => {
  const { t } = useTranslation()
  const { order, variant } = useMachineContext()
  const orderName = order?.name
  const componentName = variant?.name

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle onClose={onCancel}>{t('messages.workAtTheWorkstationHasBeenEnded')}</DialogTitle>
      <DialogContent>
        <Trans i18nKey={'messages.thePlannerHasEndedWorkOnTheComponentInTheOrderAtYourWorkstation'}>
          The planner has ended work on the <Bold>{{ componentName }}</Bold> component in the
          <Bold> {{ orderName }}</Bold> order at your workstation.
        </Trans>{' '}
        {t('messages.retoolOrFinishWorkingOnTheCurrentBunch')}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant='text'>
          {t('labels.finishTheCurrentBunch')}
        </Button>
        <Button onClick={onConfirm}>{t('labels.startRetool')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EnforceRetoolFromOrderDialog
