import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { WorkstationState } from 'Consts/WorkstationStates'
import { CheckListSteps, MachineCatalogCategoryReasonDto, PotentialNOK, Snapshot, Status } from 'Types'

interface ProductionContextInterface {
  status: Status | null
  setStatus: React.Dispatch<React.SetStateAction<Status | null>>
  lastSnapshot: Snapshot | null
  setLastSnapshot: React.Dispatch<React.SetStateAction<Snapshot | null>>
  isLoadingWorkstationStatus: boolean
  setIsLoadingWorkstationStatus: React.Dispatch<React.SetStateAction<boolean>>
  isLoadingWorkstationStatusError: boolean
  setIsLoadingWorkstationStatusError: React.Dispatch<React.SetStateAction<boolean>>
  lastHourOverride: boolean
  setLastHourOverride: React.Dispatch<React.SetStateAction<boolean>>
  shiftOverride: boolean
  setShiftOverride: React.Dispatch<React.SetStateAction<boolean>>
  selectedFailureReason: MachineCatalogCategoryReasonDto | null
  setSelectedFailureReason: React.Dispatch<React.SetStateAction<MachineCatalogCategoryReasonDto | null>>
  unconfirmedStatus: WorkstationState | null
  setUnconfirmedStatus: React.Dispatch<React.SetStateAction<WorkstationState | null>>
  newShortages: number
  setNewShortages: React.Dispatch<React.SetStateAction<number>>
  selectedCallForemanReason: MachineCatalogCategoryReasonDto | null
  setSelectedCallForemanReason: React.Dispatch<React.SetStateAction<MachineCatalogCategoryReasonDto | null>>
  selectedIdleReason: MachineCatalogCategoryReasonDto | null
  setSelectedIdleReason: React.Dispatch<React.SetStateAction<MachineCatalogCategoryReasonDto | null>>
  selectedCheckList: CheckListSteps | null
  setSelectedCheckList: React.Dispatch<React.SetStateAction<CheckListSteps | null>>
  resetShift: () => void
  enforceReasonsClassification: boolean
  setEnforceReasonsClassification: React.Dispatch<React.SetStateAction<boolean>>
  potentialNOKCount: number
  setPotentialNOKCount: React.Dispatch<React.SetStateAction<number>>
  potentialNOKToReportCount: number
  setPotentialNOKToReportCount: React.Dispatch<React.SetStateAction<number>>
  producedPotentialNOK: PotentialNOK[]
  setProducedPotentialNOK: React.Dispatch<React.SetStateAction<PotentialNOK[]>>
  unclassifiedInvalidBunchId: string | null
  setUnclassifiedInvalidBunchId: React.Dispatch<React.SetStateAction<string | null>>
}

const ProductionContext = createContext<ProductionContextInterface | undefined>(undefined)

export function useProductionContext() {
  const context = useContext(ProductionContext)
  if (context === undefined) {
    throw new Error('useProductionContext must be within ProductionContext')
  }

  return context
}

export const ProductionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [status, setStatus] = useState<Status | null>(null)
  const [newShortages, setNewShortages] = useState(1)
  const [potentialNOKCount, setPotentialNOKCount] = useState(0)
  const [producedPotentialNOK, setProducedPotentialNOK] = useState<PotentialNOK[]>([])
  const [potentialNOKToReportCount, setPotentialNOKToReportCount] = useState(0)
  const [lastSnapshot, setLastSnapshot] = useState<Snapshot | null>(null)
  const [isLoadingWorkstationStatus, setIsLoadingWorkstationStatus] = useState(true)
  const [isLoadingWorkstationStatusError, setIsLoadingWorkstationStatusError] = useState(false)
  const [lastHourOverride, setLastHourOverride] = useState(false)
  const [shiftOverride, setShiftOverride] = useState(false)
  const [enforceReasonsClassification, setEnforceReasonsClassification] = useState(false)
  const [selectedFailureReason, setSelectedFailureReason] = useState<MachineCatalogCategoryReasonDto | null>(null)
  const [unconfirmedStatus, setUnconfirmedStatus] = useState<WorkstationState | null>(null)
  const [selectedCallForemanReason, setSelectedCallForemanReason] = useState<MachineCatalogCategoryReasonDto | null>(
    null,
  )
  const [selectedIdleReason, setSelectedIdleReason] = useState<MachineCatalogCategoryReasonDto | null>(null)
  const [selectedCheckList, setSelectedCheckList] = useState<CheckListSteps | null>(null)
  const [unclassifiedInvalidBunchId, setUnclassifiedInvalidBunchId] = useState<string | null>(null)

  const resetShift = useCallback(() => {
    setShiftOverride(true)
    setLastSnapshot(null)
  }, [])

  const value: ProductionContextInterface = useMemo(
    () => ({
      status,
      setStatus,
      lastSnapshot,
      setLastSnapshot,
      isLoadingWorkstationStatus,
      setIsLoadingWorkstationStatus,
      isLoadingWorkstationStatusError,
      setIsLoadingWorkstationStatusError,
      lastHourOverride,
      setLastHourOverride,
      shiftOverride,
      setShiftOverride,
      selectedFailureReason,
      setSelectedFailureReason,
      unconfirmedStatus,
      setUnconfirmedStatus,
      newShortages,
      setNewShortages,
      selectedCallForemanReason,
      setSelectedCallForemanReason,
      selectedIdleReason,
      setSelectedIdleReason,
      resetShift,
      enforceReasonsClassification,
      setEnforceReasonsClassification,
      potentialNOKCount,
      setPotentialNOKCount,
      potentialNOKToReportCount,
      setPotentialNOKToReportCount,
      selectedCheckList,
      setSelectedCheckList,
      producedPotentialNOK,
      setProducedPotentialNOK,
      unclassifiedInvalidBunchId,
      setUnclassifiedInvalidBunchId,
    }),
    [
      status,
      lastSnapshot,
      isLoadingWorkstationStatus,
      isLoadingWorkstationStatusError,
      lastHourOverride,
      shiftOverride,
      selectedFailureReason,
      unconfirmedStatus,
      newShortages,
      selectedCallForemanReason,
      selectedIdleReason,
      resetShift,
      enforceReasonsClassification,
      potentialNOKCount,
      potentialNOKToReportCount,
      selectedCheckList,
      producedPotentialNOK,
      unclassifiedInvalidBunchId,
    ],
  )

  return <ProductionContext.Provider value={value}>{children}</ProductionContext.Provider>
}
