import { endOfDay, startOfDay } from 'date-fns'
import { getTechnologyNameWithRevision } from 'Features/technologies'

import { OrderStatus, OrderStatuses } from 'Consts/OrderStatuses'
import WorkstationStates from 'Consts/WorkstationStates'
import colors from 'Theme/colors'
import type { GetOrderDto, OrderDto, OrderStatusDto, PostOrderDto } from 'Types'

import { type OrderFormValues } from './OrderForm'
import { OrderTableItem } from './types'

type OrderComponentDto = OrderDto['orderComponents'][0]
type OrderComponentMachineDto = OrderComponentDto['orderComponentMachines'][0]

export const getColor = (status: OrderStatus) => {
  if (status === OrderStatuses.Ended) return colors.green
  if (status === OrderStatuses.Stopped) return colors.red
  if (status === OrderStatuses.Started) return colors.blue
  if (status === OrderStatuses.NotStarted) return colors.americanSilver

  return colors.gunPowder
}

export const isOrderDelayed = ({ finishDate, endDate }: { finishDate?: string | null; endDate: string }) => {
  if (finishDate) {
    return new Date(finishDate) > new Date(endDate)
  }

  return new Date() > new Date(endDate)
}

export const mapOrdersDtoToOrderTableItems = (orders: OrderDto[]): OrderTableItem[] => {
  return orders.map(mapOrderDtoToOrderTableItem)
}

const mapOrderDtoToOrderTableItem = (order: OrderDto): OrderTableItem => {
  return {
    id: order.id,
    name: order.name,
    technologyName: getTechnologyNameWithRevision(order.technologyName, order.technologyRevision),
    workstations: getUniqueOrderWorkstationCount(order).toString(),
    status: order.status,
    units: order.units,
    elapsedTime: order.leadElapsedTime,
    predictedTime: order.leadPredictedTime,
    startDate: order.startDate,
    endDate: order.endDate,
    finishDate: order.finishDate,
    isCompletionAtRisk: getIsOrderCompletionAtRisk(order),
    isCompletionImpossible: getIsOrderCompletionImpossible(order),
    subRows: order.orderComponents.map((oc) => ({
      id: oc.id,
      name: oc.name,
      workstations: getUniqueOrderComponentWorkstationCount(oc).toString(),
      status: oc.status,
      units: oc.units,
      elapsedTime: oc.leadElapsedTime,
      predictedTime: oc.leadPredictedTime,
      isCompletionAtRisk: getIsComponentCompletionAtRisk(oc),
      isCompletionImpossible: getIsComponentCompletionImpossible(oc),
      subRows: oc.orderComponentMachines.map((ocm) => ({
        id: ocm.id,
        name: '',
        workstations: ocm.machineName,
        status: ocm.status,
        units: ocm.units,
        elapsedTime: ocm.elapsedTime,
        predictedTime: ocm.predictedTime,
        stopReason: ocm.stopReason,
        isRemovable: ocm.isRemovable,
        workstationStatus: ocm.machineStatus,
        isCompletionAtRisk: getIsOrderComponentMachineCompletionAtRisk(ocm),
        isCompletionImpossible: getIsComponentMachineCompletionImpossible(ocm),
        isEssentialForCompletion: getIsComponentMachineEssential(oc, ocm),
        isActive: ocm.isActive,
        isDeleted: ocm.isDeleted,
      })),
    })),
  }
}

function getUniqueOrderWorkstationCount(order: OrderDto) {
  const workstations = order.orderComponents.flatMap((oc) => oc.orderComponentMachines.map((ocm) => ocm.machineName))

  return [...new Set(workstations)].length
}

function getUniqueOrderComponentWorkstationCount(orderComponent: OrderComponentDto) {
  const workstations = orderComponent.orderComponentMachines.map((ocm) => ocm.machineId)

  return [...new Set(workstations)].length
}

const getIsOrderCompletionAtRisk = (order: OrderDto) => {
  return order.orderComponents.some(getIsComponentCompletionAtRisk)
}

const getIsComponentCompletionAtRisk = (orderComponent: OrderComponentDto) => {
  return orderComponent.orderComponentMachines.some(getIsOrderComponentMachineCompletionAtRisk)
}

export const getIsOrderComponentMachineCompletionAtRisk = (
  orderComponentMachine: Pick<OrderComponentMachineDto, 'status' | 'machineStatus'>,
) => {
  const isMachineWorkingWithFailure =
    orderComponentMachine.status === OrderStatuses.Started &&
    orderComponentMachine.machineStatus === WorkstationStates.WORK_WITH_FAILURE
  const isMachineStatusRiskyForCompletion =
    orderComponentMachine.machineStatus === WorkstationStates.FAILURE ||
    orderComponentMachine.machineStatus === WorkstationStates.WORK_WITH_FAILURE ||
    orderComponentMachine.machineStatus === WorkstationStates.NOT_WORKING
  const hasMachineStoppedWorkingWithRiskyStatus =
    orderComponentMachine.status === OrderStatuses.Stopped && isMachineStatusRiskyForCompletion

  return isMachineWorkingWithFailure || hasMachineStoppedWorkingWithRiskyStatus
}

export const getIsOrderCompletionImpossible = (order: OrderDto) => {
  return order.orderComponents.some(getIsComponentCompletionImpossible)
}

const getIsComponentCompletionImpossible = (orderComponent: OrderComponentDto) => {
  return orderComponent.orderComponentMachines.every(getIsComponentMachineCompletionImpossible)
}

const getIsComponentMachineCompletionImpossible = ({
  isActive,
  isDeleted,
}: {
  isActive: boolean
  isDeleted: boolean
}) => {
  return !isActive || isDeleted
}

const getIsComponentMachineEssential = (
  orderComponent: OrderComponentDto,
  orderComponentMachine: OrderComponentMachineDto,
) => {
  const machinesAvailableForWork = orderComponent.orderComponentMachines.filter((ocm) => ocm.isActive && !ocm.isDeleted)

  return machinesAvailableForWork.length === 1 && machinesAvailableForWork[0].id === orderComponentMachine.id
}

export function mapStatusesToStatusFilterOptions(statuses: OrderStatusDto[]) {
  return statuses.map((s) => ({ ...s, color: getColor(s.name) }))
}

export const getIsComponentMachineCompletable = ({
  produced,
  planned,
  isComponentMachineEnded,
}: {
  produced: number
  planned: number
  isComponentMachineEnded: boolean
}) => {
  return getIsOrderComponentGoalReached({ produced, planned }) && !isComponentMachineEnded
}

export const getIsOrderRetoolReasonRequired = ({
  produced,
  planned,
  orderComponentMachineStatus,
}: {
  produced: number
  planned: number
  orderComponentMachineStatus: OrderStatus
}) => {
  return !getIsOrderComponentGoalReached({ produced, planned }) && orderComponentMachineStatus !== OrderStatuses.Ended
}

export const getIsOrderComponentGoalReached = ({ produced, planned }: { produced: number; planned: number }) => {
  return produced >= planned
}

export const mapOrderFormValuesToPostDto = (values: OrderFormValues): PostOrderDto => {
  return {
    ...values,
    startDate: startOfDay(values.startDate).toISOString(),
    endDate: endOfDay(values.endDate).toISOString(),
  }
}

export const mapGetOrderDtoToFormValues = (data: GetOrderDto): OrderFormValues => {
  return {
    ...data,
    startDate: new Date(data.startDate),
    endDate: new Date(data.endDate),
  }
}
