const WorkstationStates = {
  NOT_WORKING: 'NotWorking',
  MICROIDLE: 'Microidle',
  IDLE: 'Idle',
  NORMAL_WORK: 'NormalWork',
  FAILURE: 'Failure',
  WORK_WITH_FAILURE: 'WorkWithFailure',
  RETOOL: 'Retool',
} as const

type WorkstationStatesKeys = keyof typeof WorkstationStates
export type WorkstationState = (typeof WorkstationStates)[WorkstationStatesKeys]

export const WorkstationStatesTranslationKeys = {
  [WorkstationStates.FAILURE as string]: 'labels.failure',
  [WorkstationStates.IDLE as string]: 'labels.idle',
  [WorkstationStates.MICROIDLE as string]: 'labels.microidle',
  [WorkstationStates.NORMAL_WORK as string]: 'labels.normalWork',
  [WorkstationStates.NOT_WORKING as string]: 'labels.notWorking',
  [WorkstationStates.RETOOL as string]: 'labels.retool',
  [WorkstationStates.WORK_WITH_FAILURE as string]: 'labels.workWithFailure',
} as const

export default WorkstationStates
