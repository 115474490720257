import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, DocumentProps, Page, pdfjs } from 'react-pdf'
import Autosizer from 'react-virtualized-auto-sizer'
import { ListOnItemsRenderedProps, VariableSizeList } from 'react-window'
import { styled } from '@mui/material'

import { AnyType } from 'Types'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface ItemDataType {
  scale: number
}
const renderPage = ({ index, style, data }: { index: number; style: AnyType; data: ItemDataType }) => {
  const { scale } = data
  const pageNumber = index + 1

  return (
    <div style={style} key={`page-${pageNumber}`}>
      <Page pageNumber={pageNumber} scale={scale} />
    </div>
  )
}

interface PdfDocumentProps extends DocumentProps {
  scale: number
}
const ValidationMessage = styled('div')(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
  color: theme.palette.text.primary,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

const PdfDocument: React.FC<PdfDocumentProps> = ({ scale, onLoadSuccess, ...otherDocumentProps }) => {
  const [pdfDocument, setPdfDocument] = useState<pdfjs.PDFDocumentProxy>()
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [pageDimensions, setPageDimensions] = useState(new Map())
  const listRef = useRef<VariableSizeList>()
  const [isLoading, setIsLoading] = useState(false)
  const [currentyVissiblePage, setCurrentyVissiblePage] = useState<number>(1)
  const { t } = useTranslation()

  const loadPageDimensions: (pdf: pdfjs.PDFDocumentProxy) => void = (pdf) => {
    const pageNumbers = [...Array(pdf.numPages).keys()].map((x) => x + 1)
    const promisses = pageNumbers.map((pageNumber) => {
      return pdf.getPage(pageNumber)
    })

    Promise.all(promisses)
      .then((pages) => {
        const initialPageDimensions = new Map()

        pages.forEach((page) => {
          const { width, height } = page.getViewport({ scale })

          initialPageDimensions.set(page.pageNumber, { width, height })
        })

        setPageDimensions(initialPageDimensions)
      })
      .finally(() => {
        setIsLoading(false)
        if (listRef?.current && listRef.current.scrollToItem && currentyVissiblePage !== 1) {
          listRef.current.scrollToItem(currentyVissiblePage - 1)
        }
      })
  }

  useEffect(() => {
    if (pdfDocument) {
      setIsLoading(true)
      loadPageDimensions(pdfDocument)
      listRef?.current?.resetAfterIndex?.(0)
    }
  }, [pdfDocument, scale])

  const handleDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setPdfDocument(pdf)
    setNumberOfPages(pdf.numPages)
    if (onLoadSuccess) {
      onLoadSuccess(pdf)
    }
  }

  const getItemSite = (index: number) => {
    return pageDimensions.get(index + 1).height
  }

  const handlePageRendered = ({ visibleStartIndex, visibleStopIndex }: ListOnItemsRenderedProps) => {
    setCurrentyVissiblePage(Math.round((visibleStartIndex + visibleStopIndex) / 2) + 1)
  }

  return (
    <Autosizer>
      {({ height = 0, width = 0 }) => {
        return (
          <Document
            onLoadSuccess={handleDocumentLoadSuccess}
            error={<ValidationMessage>{t('messages.fileNotExists')}</ValidationMessage>}
            {...otherDocumentProps}
          >
            {!!pageDimensions.size && !isLoading && (
              <VariableSizeList
                // @ts-ignore
                ref={listRef}
                height={height}
                width={width}
                itemCount={numberOfPages}
                itemSize={getItemSite}
                itemData={{ scale }}
                overscanCount={2}
                estimatedItemSize={getItemSite(0)}
                onItemsRendered={handlePageRendered}
              >
                {renderPage}
              </VariableSizeList>
            )}
          </Document>
        )
      }}
    </Autosizer>
  )
}

export default PdfDocument
