import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { EndedOrderAlert } from 'Features/orders'

import { OrderExecutors } from 'Consts/executors'
import { useMachineContext } from 'Context/MachineContext'

const OperatorAlertsHandler = () => {
  const [endedOrderClosed, setEndedOrderClosed] = useState(false)
  const { order } = useMachineContext()
  const isCurrentOrderEndedByPlanner = !!order?.finishExecutor && order?.finishExecutor !== OrderExecutors.OPERATOR
  const orderComponentId = order?.componentId

  useEffect(() => {
    setEndedOrderClosed(false)
  }, [orderComponentId])

  if (isCurrentOrderEndedByPlanner && !endedOrderClosed) {
    return (
      <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
        <EndedOrderAlert onClose={() => setEndedOrderClosed(true)} />
      </Box>
    )
  }

  return null
}

export default OperatorAlertsHandler
