import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ExitToApp as LogoutIcon } from '@mui/icons-material'

import AuthManager from 'Auth/AuthManager'
import { LocalStorageKeys } from 'Consts/LocalStorageKeys'
import { useDialogsContext } from 'Context/DialogsContext'
import { useUser as useUserContext } from 'Context/UserContext'
import EndWorkDialog from 'Layout/Modals/EndWorkDialog'
import paths from 'Routes/paths'

import SideNavItem from './SideNavItem'

type Props = {
  collapsed?: boolean
}

const LogoutMenuItem: React.FC<Props> = ({ collapsed }: Props) => {
  const { user } = useUserContext()
  const { removeCardDialogOpen, setRemoveCardDialogOpen } = useDialogsContext()
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()

  const handleLogout = () => {
    if (user?.loginMethod === 'rfid') {
      setRemoveCardDialogOpen(true)
    } else {
      setIsLoggingOut(true)

      if (!isLoggingOut) {
        AuthManager.logout()
          .then(() => {
            setIsLoggingOut(false)
            history.push(paths.login)
            localStorage.removeItem(LocalStorageKeys.REDIRECT_PATH)
          })
          .catch(() => setIsLoggingOut(false))
      }
    }
  }

  if (!user) {
    return null
  }

  return (
    <>
      <SideNavItem
        collapsed={collapsed}
        border='top'
        onClick={handleLogout}
        Icon={LogoutIcon}
        label={t('labels.logout')}
      />
      {removeCardDialogOpen && (
        <EndWorkDialog open={removeCardDialogOpen} onClose={() => setRemoveCardDialogOpen(false)} />
      )}
    </>
  )
}

export default LogoutMenuItem
