const Executors = {
  OPERATOR: 'Operator',
  PLANNER: 'Planner',
  SYSTEM: 'System',
  FOREMAN: 'Foreman',
  TECHNOLOGIST: 'Technologist',
} as const

export const ExecutorTranslationKeys = {
  [Executors.OPERATOR as string]: 'labels.operator',
  [Executors.PLANNER as string]: 'labels.planner',
  [Executors.FOREMAN as string]: 'labels.foreman',
  [Executors.TECHNOLOGIST as string]: 'labels.technologist',
  [Executors.SYSTEM as string]: 'labels.system',
} as const

export const OrderExecutors = {
  OPERATOR: Executors.OPERATOR,
  PLANNER: Executors.PLANNER,
  SYSTEM: Executors.SYSTEM,
} as const

type OrderExecutorsKeys = keyof typeof OrderExecutors
export type OrderExecutor = (typeof OrderExecutors)[OrderExecutorsKeys]

export const CorrectiveExecutors = {
  FOREMAN: Executors.FOREMAN,
  TECHNOLOGIST: Executors.TECHNOLOGIST,
} as const

type CorrectiveExecutorsKeys = keyof typeof CorrectiveExecutors
export type CorrectiveExecutor = (typeof CorrectiveExecutors)[CorrectiveExecutorsKeys]
