import { createTheme } from '@mui/material/styles'

import { palette } from './colors'
import ComponentsOverride from './ComponentsOverride'
import customShadows from './shadows'

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    // common
    white: string
    black: string
    blue: PaletteOptions['primary']
    yellow: PaletteOptions['primary']
    // statuses
    notWorking: PaletteOptions['primary']
    normalWork: PaletteOptions['primary']
    workWithFailure: PaletteOptions['primary']
    retool: PaletteOptions['primary']
    failure: PaletteOptions['primary']
    idle: PaletteOptions['primary']
    microidle: PaletteOptions['primary']
    // other
    accident: PaletteOptions['primary']
    scrap: PaletteOptions['primary']
    rework: PaletteOptions['primary']
    dark: PaletteOptions['primary']
  }

  interface Palette {
    // common
    white: string
    black: string
    blue: Palette['primary']
    yellow: Palette['primary']
    // statuses
    notWorking: Palette['primary']
    normalWork: Palette['primary']
    workWithFailure: Palette['primary']
    retool: Palette['primary']
    failure: Palette['primary']
    idle: Palette['primary']
    microidle: Palette['primary']
    // other
    accident: Palette['primary']
    scrap: Palette['primary']
    rework: Palette['primary']
    dark: Palette['primary']
  }

  interface TypeBackground {
    lightGrey: string
    grey: string
    backdrop: string
  }
}

declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    custom: {
      sidebar: {
        width: number
        collapsedWidth: number
      }
    }
  }

  interface Theme {
    custom: {
      sidebar: {
        width: number
        collapsedWidth: number
      }
    }
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
  }
}

const theme = createTheme({
  palette: {
    primary: palette.primary,
    secondary: palette.secondary,
    background: palette.background,
    text: palette.text,
    success: palette.success,
    info: palette.info,
    error: palette.error,
    warning: palette.warning,
    // common
    white: palette.common.white,
    black: palette.common.black,
    blue: {
      main: palette.common.blue,
      contrastText: palette.common.white,
    },
    yellow: {
      main: palette.common.yellow,
      contrastText: palette.text.primary,
    },
    // statuses
    notWorking: palette.status.notWorking,
    normalWork: palette.status.normalWork,
    workWithFailure: palette.status.workWithFailure,
    failure: palette.status.failure,
    idle: palette.status.idle,
    microidle: palette.status.microidle,
    retool: palette.status.retool,
    // other
    accident: palette.accident,
    scrap: palette.scrap,
    rework: palette.rework,
    dark: palette.dark,
  },
  shadows: customShadows,
  components: ComponentsOverride,
  typography: {
    subtitle1: {
      fontSize: 22,
    },
    subtitle2: {
      fontSize: 18,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      xxl: 3000,
    },
  },
  custom: {
    sidebar: {
      width: 224,
      collapsedWidth: 68,
    },
  },
})

theme.typography.body1 = {
  ...theme.typography.body1,
  [theme.breakpoints.up('xxl')]: {
    fontSize: '2rem',
  },
}
theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.75rem',
  },
}
theme.typography.caption = {
  ...theme.typography.caption,
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.5rem',
  },
}
theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  [theme.breakpoints.up('xxl')]: {
    fontSize: '2.25rem',
  },
}
theme.typography.h6 = {
  ...theme.typography.h6,
  [theme.breakpoints.up('xxl')]: {
    fontSize: '2.5rem',
  },
}
theme.typography.h5 = {
  ...theme.typography.h5,
  [theme.breakpoints.up('xxl')]: {
    fontSize: '3rem',
  },
}

export default theme
