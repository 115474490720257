import { useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'

import HubMethods from 'Consts/HubMethods'
import WorkstationStates from 'Consts/WorkstationStates'
import { useDialogsContext } from 'Context/DialogsContext'
import { useMachineContext } from 'Context/MachineContext'
import { useProductionContext } from 'Context/ProductionContext'
import { useShiftsConfigurationContext } from 'Context/ShiftsContext/ShiftsContext'
import { useSignalRContext } from 'Context/SignalRContext'
import { useUser as useUserContext } from 'Context/UserContext'
import { getTimeToShiftEndInSeconds, isEarlierThanGivenShift } from 'Helpers/Shifts'
import { getCreationDate } from 'Helpers/Time'
import { useActiveMachines } from 'Hooks/workstations/useActiveMachines'
import { useWorkstationCurrentOrder } from 'Hooks/workstations/useWorkstationCurrentOrder'
import { useWorkstationStatus } from 'Hooks/workstations/useWorkstationStatus'
import { Snapshot, SnapshotBase, WorkstationStatusApiResponse, WorkstationStatusChangedMessage } from 'Types'
import {
  BunchCancelledMessage,
  BunchProducedMessage,
  IdleWasSetOnWorkstationMessage,
  InvalidComponentsReportedMessage,
  InvalidUnitsReportedMessage,
  MachineModeChangedMessage,
  OrderComponentMachineCompletedMessage,
  OrderComponentMachineGoalRecalculatedMessage,
  OrderComponentMachineResumedMessage,
  OrderComponentValidUnitsCountChangedMessage,
  OrderNameChanged,
  PlannedComponentMachineEndDateChangedMessage,
  PotentialNokProducedMessage,
  ProductionGoalRecalculatedMessage,
  RetoolEndedMessage,
  ReworkBunchReportedMessage,
  StartedRetoolMessage,
  WorkstationRetooledMessage,
} from 'Types/HubMessages'

import { calculateOrderActualGoal, calculatePacedGoal, getTimeFromLastRetoolInSeconds } from './helpers'

const mapWorkstationStatusDtoToSnapshot = (data: WorkstationStatusApiResponse): Snapshot => {
  const baseSnapshot: SnapshotBase = {
    creationDate: getCreationDate(data.stateDurationInSeconds).toISOString(),
    reworkAvailable: data.reworkAvailable,
    lastBunchProductionDate: data.lastBunchProductionDate,
    lastRetoolDate: data.lastRetoolDate,
  }

  if (data.workingMode === 'Serial') {
    return {
      ...baseSnapshot,
      workingMode: data.workingMode,
      hourlyGoal: data.hourlyGoal,
      hourlyPacedGoal: data.hourlyPacedGoal,
      hourlyInvalidUnitsCount: data.hourlyInvalidUnitsCount,
      shiftInvalidUnitsCount: data.shiftInvalidUnitsCount,
      reworksHourlyCount: data.hourlyReworkCount,
      reworksShiftCount: data.shiftReworkCount,
      reworkAvailable: data.reworkAvailable,
      shiftGoal: data.shiftGoal,
      shiftPacedGoal: data.shiftPacedGoal,
      hourlyValidUnitsCount: data.hourlyValidUnitsCount,
      shiftValidUnitsCount: data.shiftValidUnitsCount,
      shiftInvalidComponentsCount: data.shiftInvalidComponentsCount,
      hourlyInvalidComponentsCount: data.hourlyInvalidComponentsCount,
    }
  }

  return {
    ...baseSnapshot,
    workingMode: data.workingMode,
    orderComponentValidUnitsCount: data.orderComponentValidUnitsCount,
    orderComponentGoal: data.orderComponentGoal,
    orderComponentMachineValidUnitsCount: data.orderComponentMachineValidUnitsCount,
    orderComponentMachineInvalidUnitsCount: data.orderComponentMachineInvalidUnitsCount,
    orderComponentMachineReworkCount: data.orderComponentMachineReworkCount,
    orderComponentMachineActualGoal: data.orderComponentMachineActualGoal,
    orderComponentMachinePacedActualGoal: data.orderComponentMachinePacedActualGoal,
    orderComponentMachineInvalidComponentsCount: data.orderComponentMachineInvalidComponentsCount,
    orderComponentMachineProductPlannedEndDate: data.orderComponentMachineProductPlannedEndDate,
    lastComponentBunchProductionDate: data.lastComponentBunchProductionDate,
  }
}

const ProductionHandler = () => {
  const { hubConnection } = useSignalRContext()
  const { currentShift } = useShiftsConfigurationContext()
  const { setStatus, resetShift, setEnforceReasonsClassification, setPotentialNOKCount, setProducedPotentialNOK } =
    useProductionContext()
  const {
    setLastHourOverride,
    setShiftOverride,
    setLastSnapshot,
    lastSnapshot,
    setIsLoadingWorkstationStatus,
    setIsLoadingWorkstationStatusError,
  } = useProductionContext()
  const {
    machine,
    setMachine,
    machineMode,
    setMachineMode,
    variant,
    setVariant,
    order,
    setOrder,
    setIsOrderAndVariantLoaded,
    setIsReworkMode,
  } = useMachineContext()
  const { user } = useUserContext()
  const { setMultipleUnitsDialogOpen } = useDialogsContext()
  const queryClient = useQueryClient()
  const machineId = machine?.id

  useActiveMachines({
    enabled: !!machineId,
    onSuccess: (activeMachines) => {
      if (!activeMachines.find(({ id }) => id === machineId)) {
        setMachine(undefined)
      }
    },
  })

  useWorkstationCurrentOrder(machineId!, {
    enabled: !!machineId,
    onSuccess: ({ currentOrder, currentVariant, machineMode: currentMachineMode }) => {
      setOrder(currentOrder ?? null)
      setVariant(currentVariant ?? null)
      setMachineMode(currentMachineMode)
      setIsReworkMode(!!currentVariant?.reworkMode)
      setIsOrderAndVariantLoaded(true)
    },
  })

  const isWorkstationStatusQueryEnabled = !!machineId && !!user
  const {
    isFetching: isLoadingStatus,
    isError: isLoadingStatusError,
    refetch: refetchWorkstationStatus,
  } = useWorkstationStatus(machineId!, {
    enabled: isWorkstationStatusQueryEnabled,
    onSuccess: (workstationStatus) => {
      setMachine((prevMachine) => {
        if (prevMachine) {
          return {
            ...prevMachine,
            actualUnitProductionCount: workstationStatus.actualUnitProductionCount,
            defaultUnitProductionCount: workstationStatus.defaultUnitProductionCount,
          }
        }

        return prevMachine
      })
      setStatus((prevStatus) => {
        if (!prevStatus || new Date(workstationStatus.stateBeginsAt) >= new Date(prevStatus.beginsAt)) {
          return {
            type: workstationStatus.stateCode,
            durationInSeconds: workstationStatus.stateDurationInSeconds,
            beginsAt: workstationStatus.stateBeginsAt,
          }
        }

        return prevStatus
      })

      setEnforceReasonsClassification(workstationStatus.reportIdleReason)
      setLastSnapshot(mapWorkstationStatusDtoToSnapshot(workstationStatus))

      if (workstationStatus.potentialNOKCount) {
        setPotentialNOKCount(workstationStatus.potentialNOKCount)
      }

      const shouldOpenMultipleUnitsDialog =
        workstationStatus.actualUnitProductionCount > 1 &&
        workstationStatus.stateCode === WorkstationStates.WORK_WITH_FAILURE
      if (shouldOpenMultipleUnitsDialog) {
        setMultipleUnitsDialogOpen(true)
      }
    },
  })

  const refetchLastSnapshot = useCallback(() => {
    if (isWorkstationStatusQueryEnabled) {
      setIsLoadingWorkstationStatus(isLoadingStatus)
      setLastSnapshot(null)
      refetchWorkstationStatus()
    }
  }, [refetchWorkstationStatus, isWorkstationStatusQueryEnabled])

  const resetShiftAndRefetchLastSnapshot = useCallback(() => {
    resetShift()
    refetchLastSnapshot()
  }, [refetchLastSnapshot, resetShift])

  useEffect(() => {
    setIsLoadingWorkstationStatus(isLoadingStatus)
    setIsLoadingWorkstationStatusError(isLoadingStatusError)
  }, [isLoadingStatus, isLoadingStatusError])

  useEffect(() => {
    const handleBunchProducedMessage = (message: BunchProducedMessage) => {
      if (!currentShift || isEarlierThanGivenShift(new Date(message.productionDate), currentShift)) {
        return
      }

      setLastHourOverride(false)
      setShiftOverride(false)
      setLastSnapshot((prevSnapshot) => {
        if (
          !prevSnapshot?.lastBunchProductionDate ||
          new Date(prevSnapshot.lastBunchProductionDate) < new Date(message.productionDate)
        ) {
          const newSnapshotBase: SnapshotBase = {
            creationDate: new Date().toISOString(),
            lastBunchProductionDate: message.productionDate,
            reworkAvailable: true,
          }

          if (message.workingMode === 'Serial') {
            const { hourlyInvalidUnitsCount, shiftInvalidUnitsCount, hourlyValidUnitsCount, shiftValidUnitsCount } =
              message

            return {
              ...prevSnapshot,
              ...newSnapshotBase,
              hourlyInvalidUnitsCount,
              shiftInvalidUnitsCount,
              hourlyValidUnitsCount,
              shiftValidUnitsCount,
            }
          }

          const {
            orderComponentMachineInvalidUnitsCount,
            orderComponentValidUnitsCount,
            orderComponentMachineValidUnitsCount,
            productionDate,
          } = message

          return {
            ...prevSnapshot,
            ...newSnapshotBase,
            lastComponentBunchProductionDate: productionDate,
            orderComponentValidUnitsCount,
            orderComponentMachineValidUnitsCount,
            orderComponentMachineInvalidUnitsCount,
          }
        }

        return prevSnapshot
      })
    }

    const handleReworkBunchReportedMessage = (message: ReworkBunchReportedMessage) => {
      setLastSnapshot((prevSnapshot) => {
        const newSnapshotBase: SnapshotBase = {
          creationDate: new Date().toISOString(),
          reworkAvailable: false,
        }

        if (message.workingMode === 'Serial') {
          const { hourlyInvalidUnitsCount, shiftInvalidUnitsCount, hourlyValidUnitsCount, shiftValidUnitsCount } =
            message

          return {
            ...prevSnapshot,
            ...newSnapshotBase,
            hourlyInvalidUnitsCount,
            shiftInvalidUnitsCount,
            hourlyValidUnitsCount,
            shiftValidUnitsCount,
            reworksHourlyCount: message.hourlyReworkCount,
            reworksShiftCount: message.shiftReworkCount,
          }
        }

        const {
          orderComponentValidUnitsCount,
          orderComponentMachineValidUnitsCount,
          orderComponentMachineInvalidUnitsCount,
          orderComponentMachineReworkCount,
        } = message

        return {
          ...prevSnapshot,
          ...newSnapshotBase,
          orderComponentValidUnitsCount,
          orderComponentMachineValidUnitsCount,
          orderComponentMachineInvalidUnitsCount,
          orderComponentMachineReworkCount,
        }
      })
    }

    const handleInvalidUnitsReportedMessage = (message: InvalidUnitsReportedMessage) => {
      setLastSnapshot((prevSnapshot) => {
        const newSnapshotBase: SnapshotBase = {
          creationDate: new Date().toISOString(),
          reworkAvailable: true,
        }

        if (message.workingMode === 'Serial') {
          const { hourlyInvalidUnitsCount, shiftInvalidUnitsCount, hourlyValidUnitsCount, shiftValidUnitsCount } =
            message

          return {
            ...prevSnapshot,
            ...newSnapshotBase,
            hourlyInvalidUnitsCount,
            shiftInvalidUnitsCount,
            hourlyValidUnitsCount,
            shiftValidUnitsCount,
          }
        }

        const {
          orderComponentValidUnitsCount,
          orderComponentMachineValidUnitsCount,
          orderComponentMachineInvalidUnitsCount,
        } = message

        return {
          ...prevSnapshot,
          ...newSnapshotBase,
          orderComponentValidUnitsCount,
          orderComponentMachineValidUnitsCount,
          orderComponentMachineInvalidUnitsCount,
        }
      })
    }

    const handleInvalidComponentsReportedMessage = (message: InvalidComponentsReportedMessage) => {
      setLastSnapshot((prevSnapshot) => {
        const newSnapshotBase: SnapshotBase = {
          creationDate: new Date().toISOString(),
        }

        if (message.workingMode === 'Serial') {
          return {
            ...prevSnapshot,
            ...newSnapshotBase,
            hourlyInvalidComponentsCount: message.hourlyInvalidComponentsCount,
            shiftInvalidComponentsCount: message.shiftInvalidComponentsCount,
          }
        }

        const { orderComponentMachineInvalidComponentsCount } = message

        return {
          ...prevSnapshot,
          ...newSnapshotBase,
          orderComponentMachineInvalidComponentsCount,
        }
      })
    }

    const handlePotentialNokProducedMessage = ({ unitsCount, productionDate }: PotentialNokProducedMessage) => {
      setPotentialNOKCount((prev) => prev + unitsCount)
      setProducedPotentialNOK((prev) => [...prev, { unitsCount, productionDate }])
    }

    const handlePlannedComponentEndDateChanged = ({
      orderComponentMachineProductPlannedEndDate,
    }: PlannedComponentMachineEndDateChangedMessage) => {
      setLastSnapshot((prevSnapshot) => {
        if (prevSnapshot) {
          return {
            ...prevSnapshot,
            orderComponentMachineProductPlannedEndDate,
          }
        }

        return prevSnapshot
      })
    }

    const handleBunchCanceledMessage = (message: BunchCancelledMessage) => {
      setLastSnapshot((prevSnapshot) => {
        const { cancelledBunchId, cancelledUnitCount } = message
        const newSnapshotBase: SnapshotBase = {
          creationDate: new Date().toISOString(),
          cancelledBunchId,
          cancelledUnitCount,
        }

        if (message.workingMode === 'Serial') {
          const {
            hourlyInvalidUnitsCount,
            shiftInvalidUnitsCount,
            hourlyReworkCount,
            hourlyValidUnitsCount,
            shiftValidUnitsCount,
            shiftReworkCount,
          } = message

          return {
            ...prevSnapshot,
            ...newSnapshotBase,
            hourlyValidUnitsCount,
            hourlyInvalidUnitsCount,
            hourlyReworkCount,
            shiftValidUnitsCount,
            shiftInvalidUnitsCount,
            shiftReworkCount,
          }
        }

        const {
          orderComponentValidUnitsCount,
          orderComponentMachineValidUnitsCount,
          orderComponentMachineInvalidUnitsCount,
          orderComponentMachineReworkCount,
        } = message

        return {
          ...prevSnapshot,
          ...newSnapshotBase,
          orderComponentValidUnitsCount,
          orderComponentMachineValidUnitsCount,
          orderComponentMachineInvalidUnitsCount,
          orderComponentMachineReworkCount,
        }
      })
    }

    const handleOrderComponentValidUnitsCountChanged = ({
      orderComponentValidUnitsCount,
      productionDate,
    }: OrderComponentValidUnitsCountChangedMessage) => {
      setLastSnapshot((prevSnapshot) => {
        if (prevSnapshot?.workingMode === 'Order') {
          const { lastComponentBunchProductionDate } = prevSnapshot

          if (
            !lastComponentBunchProductionDate ||
            new Date(lastComponentBunchProductionDate) < new Date(productionDate)
          ) {
            return {
              ...prevSnapshot,
              orderComponentValidUnitsCount,
              lastComponentBunchProductionDate: productionDate,
            }
          }
        }

        return prevSnapshot
      })
    }

    hubConnection?.on(HubMethods.BunchProduced, handleBunchProducedMessage)
    hubConnection?.on(HubMethods.ReworkBunchReported, handleReworkBunchReportedMessage)
    hubConnection?.on(HubMethods.PotentialNokProduced, handlePotentialNokProducedMessage)
    hubConnection?.on(HubMethods.InvalidUnitsReported, handleInvalidUnitsReportedMessage)
    hubConnection?.on(HubMethods.InvalidComponentsReported, handleInvalidComponentsReportedMessage)
    hubConnection?.on(HubMethods.PlannedComponentMachineEndDateChanged, handlePlannedComponentEndDateChanged)
    hubConnection?.on(HubMethods.BunchCancelled, handleBunchCanceledMessage)
    hubConnection?.on(HubMethods.OrderComponentValidUnitsCountChanged, handleOrderComponentValidUnitsCountChanged)

    return () => {
      hubConnection?.off(HubMethods.BunchProduced, handleBunchProducedMessage)
      hubConnection?.off(HubMethods.ReworkBunchReported, handleReworkBunchReportedMessage)
      hubConnection?.off(HubMethods.PotentialNokProduced, handlePotentialNokProducedMessage)
      hubConnection?.off(HubMethods.InvalidUnitsReported, handleInvalidUnitsReportedMessage)
      hubConnection?.off(HubMethods.InvalidComponentsReported, handleInvalidComponentsReportedMessage)
      hubConnection?.off(HubMethods.PlannedComponentMachineEndDateChanged, handlePlannedComponentEndDateChanged)
      hubConnection?.off(HubMethods.BunchCancelled, handleBunchCanceledMessage)
      hubConnection?.off(HubMethods.OrderComponentValidUnitsCountChanged, handleOrderComponentValidUnitsCountChanged)
    }
  }, [hubConnection, currentShift])

  useEffect(() => {
    const handleProductionGoalRecalculatedMessage = ({
      shiftGoal,
      hourlyGoal,
      hourlyPacedGoal,
      shiftPacedGoal,
    }: ProductionGoalRecalculatedMessage) => {
      setLastSnapshot((prevSnapshot) => ({
        ...prevSnapshot,
        shiftGoal,
        shiftPacedGoal,
        hourlyGoal,
        hourlyPacedGoal,
      }))
    }
    const handleOrderComponentGoalRecalculated = ({
      orderComponentGoal,
      orderComponentMachineActualGoal,
      orderComponentMachinePacedActualGoal,
    }: OrderComponentMachineGoalRecalculatedMessage) => {
      setLastSnapshot((prevSnapshot) => {
        if (prevSnapshot) {
          return {
            ...prevSnapshot,
            orderComponentGoal,
            orderComponentMachineActualGoal,
            orderComponentMachinePacedActualGoal,
          }
        }

        return prevSnapshot
      })
    }
    const handleOrderNameChanged = ({ name }: OrderNameChanged) => {
      setOrder((prev) => ({
        ...prev,
        name,
      }))
    }

    hubConnection?.on(HubMethods.ProductionGoalRecalculated, handleProductionGoalRecalculatedMessage)
    hubConnection?.on(HubMethods.OrderComponentMachineGoalRecalculated, handleOrderComponentGoalRecalculated)
    hubConnection?.on(HubMethods.OrderNameChanged, handleOrderNameChanged)

    return () => {
      hubConnection?.off(HubMethods.ProductionGoalRecalculated, handleProductionGoalRecalculatedMessage)
      hubConnection?.off(HubMethods.OrderComponentMachineGoalRecalculated, handleOrderComponentGoalRecalculated)
      hubConnection?.off(HubMethods.OrderNameChanged, handleOrderNameChanged)
    }
  }, [hubConnection])

  useEffect(() => {
    const handleStatusChanged = ({ status, beginsAt, statusDurationInSeconds }: WorkstationStatusChangedMessage) => {
      setStatus((prevStatus) => {
        if (!prevStatus || new Date(beginsAt) >= new Date(prevStatus.beginsAt)) {
          return {
            type: status,
            durationInSeconds: statusDurationInSeconds,
            beginsAt,
          }
        }

        return prevStatus
      })
    }
    const handleIdleWasSetOnWorkstation = (message: IdleWasSetOnWorkstationMessage) => {
      handleStatusChanged(message)

      if (message.enforceReasonsClassification) {
        setEnforceReasonsClassification(true)
      }
    }

    hubConnection?.on(HubMethods.WorkstationStartedWorking, handleStatusChanged)
    hubConnection?.on(HubMethods.WorkstationStoppedWorking, handleStatusChanged)
    hubConnection?.on(HubMethods.StartedNormalWork, handleStatusChanged)
    hubConnection?.on(HubMethods.StartedWorkWithFailure, handleStatusChanged)
    hubConnection?.on(HubMethods.StartedFailure, handleStatusChanged)
    hubConnection?.on(HubMethods.StartedIdle, handleStatusChanged)
    hubConnection?.on(HubMethods.IdleEnded, handleStatusChanged)
    hubConnection?.on(HubMethods.MicroidleSet, handleStatusChanged)
    hubConnection?.on(HubMethods.MicroidleEnded, handleStatusChanged)
    hubConnection?.on(HubMethods.StartedRetool, handleStatusChanged)
    hubConnection?.on(HubMethods.IdleWasSetOnWorkstation, handleIdleWasSetOnWorkstation)

    return () => {
      hubConnection?.off(HubMethods.WorkstationStartedWorking, handleStatusChanged)
      hubConnection?.off(HubMethods.WorkstationStoppedWorking, handleStatusChanged)
      hubConnection?.off(HubMethods.StartedNormalWork, handleStatusChanged)
      hubConnection?.off(HubMethods.StartedWorkWithFailure, handleStatusChanged)
      hubConnection?.off(HubMethods.StartedFailure, handleStatusChanged)
      hubConnection?.off(HubMethods.StartedIdle, handleStatusChanged)
      hubConnection?.off(HubMethods.IdleEnded, handleStatusChanged)
      hubConnection?.off(HubMethods.MicroidleSet, handleStatusChanged)
      hubConnection?.off(HubMethods.MicroidleEnded, handleStatusChanged)
      hubConnection?.off(HubMethods.StartedRetool, handleStatusChanged)
      hubConnection?.off(HubMethods.IdleWasSetOnWorkstation, handleIdleWasSetOnWorkstation)
    }
  }, [hubConnection])

  useEffect(() => {
    const handleMachineModeChanged = ({ workstationId }: MachineModeChangedMessage) => {
      if (workstationId === machineId) {
        refetchLastSnapshot()
      }
    }
    const handleWorkstationRetooledMessage = ({ workstationId }: WorkstationRetooledMessage) => {
      if (workstationId === machineId) {
        refetchLastSnapshot()
        queryClient.invalidateQueries('files-to-acknowledge')
        queryClient.invalidateQueries('workstation-files')
      }
    }
    const handleStartedRetool = ({ workstationId }: StartedRetoolMessage) => {
      if (workstationId === machineId) {
        refetchLastSnapshot()
      }
    }
    const handleRetoolEnded = ({ workstationId }: RetoolEndedMessage) => {
      if (workstationId === machineId) {
        refetchLastSnapshot()
      }
    }

    hubConnection?.on(HubMethods.MachineModeChanged, handleMachineModeChanged)
    hubConnection?.on(HubMethods.StartedRetool, handleStartedRetool)
    hubConnection?.on(HubMethods.WorkstationRetooled, handleWorkstationRetooledMessage)
    hubConnection?.on(HubMethods.RetoolEnded, handleRetoolEnded)

    return () => {
      hubConnection?.off(HubMethods.MachineModeChanged, handleMachineModeChanged)
      hubConnection?.off(HubMethods.StartedRetool, handleStartedRetool)
      hubConnection?.off(HubMethods.WorkstationRetooled, handleWorkstationRetooledMessage)
      hubConnection?.off(HubMethods.RetoolEnded, handleRetoolEnded)
    }
  }, [hubConnection, machineId, refetchLastSnapshot])

  useEffect(() => {
    const handleOrderComponentMachineCompletionStatusChanged = (
      message: OrderComponentMachineCompletedMessage | OrderComponentMachineResumedMessage,
    ) => {
      if (order?.componentId === message.orderComponentId) {
        queryClient.invalidateQueries(['workstation-current-order', machineId])
      }
    }

    hubConnection?.on(HubMethods.OrderComponentMachineCompleted, handleOrderComponentMachineCompletionStatusChanged)
    hubConnection?.on(HubMethods.OrderComponentMachineResumed, handleOrderComponentMachineCompletionStatusChanged)

    return () => {
      hubConnection?.off(HubMethods.OrderComponentMachineCompleted, handleOrderComponentMachineCompletionStatusChanged)
      hubConnection?.off(HubMethods.OrderComponentMachineResumed, handleOrderComponentMachineCompletionStatusChanged)
    }
  }, [hubConnection, machineId, order])

  useEffect(() => {
    let currentTimeIntervalId: ReturnType<typeof setInterval>
    const nextHour = new Date()
    nextHour.setHours(nextHour.getHours() + 1)
    nextHour.setMinutes(0)
    nextHour.setSeconds(0)
    nextHour.setMilliseconds(0)

    const timeToNextHour = nextHour.getTime() - new Date().getTime()

    const nextHourTimeoutId = setTimeout(() => {
      setLastHourOverride(true)

      currentTimeIntervalId = setInterval(() => setLastHourOverride(true), 60 * 60 * 1000)
    }, timeToNextHour)

    return () => {
      clearTimeout(nextHourTimeoutId)
      clearInterval(currentTimeIntervalId)
    }
  }, [])

  useEffect(() => {
    let shiftOverrideTimeout: ReturnType<typeof setTimeout>
    if (currentShift) {
      const timeOffsetForNextNewShift = 750
      const timeToShiftEnd = getTimeToShiftEndInSeconds(currentShift) * 1000 + timeOffsetForNextNewShift

      shiftOverrideTimeout = setTimeout(() => {
        resetShiftAndRefetchLastSnapshot()
      }, timeToShiftEnd)
    }

    return () => {
      if (shiftOverrideTimeout) {
        clearTimeout(shiftOverrideTimeout)
      }
    }
  }, [currentShift, resetShiftAndRefetchLastSnapshot])

  const lastRetoolDate = lastSnapshot?.lastRetoolDate
  const isOrderMode = machineMode === 'Order' && lastSnapshot?.workingMode === 'Order'

  useEffect(() => {
    let updateOrderGoalsIntervalId: ReturnType<typeof setInterval>

    if (machine && isOrderMode && variant && lastRetoolDate) {
      const { defaultUnitProductionCount } = machine
      const { cycleInSeconds, pace } = variant
      const updateInterval = Math.max(cycleInSeconds, 30) * 1000

      updateOrderGoalsIntervalId = setInterval(() => {
        const orderComponentMachineActualGoal = calculateOrderActualGoal(
          getTimeFromLastRetoolInSeconds(lastRetoolDate),
          cycleInSeconds,
          defaultUnitProductionCount,
        )
        const orderComponentPacedActualGoal = calculatePacedGoal(orderComponentMachineActualGoal, pace)

        setLastSnapshot((prev) => {
          if (prev) {
            return {
              ...prev,
              orderComponentMachineActualGoal: orderComponentMachineActualGoal,
              orderComponentMachinePacedActualGoal: orderComponentPacedActualGoal,
            }
          }

          return prev
        })
      }, updateInterval)
    }

    return () => {
      clearInterval(updateOrderGoalsIntervalId)
    }
  }, [machine, isOrderMode, variant, lastRetoolDate])

  return null
}

export default ProductionHandler
