const errors = {
  notEditableVariant: 'Given variant is not editable.',
  notRemovableWorkstation: 'Given workstation is not removable.',
  alreadyAcceptedArrival: 'Cannot accept already accepted arrival request.',
  catalogNameMustBeUnique: 'Catalog name must be unique.',
  rfIdMustBeUnique: 'RfId must be unique.',
  emailMustBeUnique: 'Email must be unique',
  cannotAssignMachines: 'Cannot assign machines',
  emailIsIncorrect: 'Email is incorrect.',
  activationTokenInvalid: 'Activation token is invalid.',
  accidentIsAcknowledged: 'Cannot acknowledge already acknowledged incident.',
  lineLayoutAlreadyExist: 'Production line layout exists for production line',
  kanbanTasksNotExist: '^Kanban tasks with ids: .* does not exists.',
  givenVariantIsNotRemovable: 'Given variant is not removable.',
  missingWorkstations: 'Missing workstations.',
  notExists: 'Not exists.',
  kanbanTasksAlreadyRelated: 'Tasks are already related.',
  catalogIsNotAssigned: 'Reason id is not assigned to correct catalog.',
  invalidUnitsCountCannotBeGreater: 'Invalid units cannot be greater than valid units count.',
  notRemovableDefaultLanguage: 'Cannot delete default language.',
  languageNotExist: 'Language does not exists.',
  languageNotActive: 'Language is not active.',
  effectiveFromDateMustMatchTheConfiguredShifts: 'Effective from date must match the configured shifts',
  shiftsMustNotOverlap: 'Shifts must not overlap and must complete 24 hours a day',
  stepsTakenAreNotAssignedToPassedChecklist: 'Steps taken are not assigned to passed check list.',
  passedWorkstationIsUsedByAnotherOperator: 'Passed workstation is used by another operator.',
  machineMustBeOccupiedToSetRetool: 'Machine must be occupied to set retool.',
  resolvedPotentialNOKsCannotBeGreaterThanShiftPotentialNOK:
    'Resolved potential NOKs cannot be greater than shift potentialNOK.',
  minutesMustEndWithZeroOrFive: 'Minutes must end with 0 or 5',
  lastBunchCannotBeTransformedIntoReworkBunch: 'Last bunch cannot be transformed into rework bunch.',
  workstationIsNotInNotWorkingState: 'Workstation is not in Not Working state',
  foremanPanelPermissionIsRequired: 'ForemanPanel Permission is required to assign lines',
  foremanDashboardPermissionIsRequired: 'ForemanDashboard Permission is required to assign lines',
  catalogCategoriesAndReasonsMustHaveSameLanguages: 'Catalog categories and reasons must have same languages.',
  workstationThatIsUsedForOrderCannotBeRemoved: 'Cannot delete machine with not ended order.',
  networkError: 'Network Error',
}

export const errorCodes = {
  machinesAreLinkedWithAnotherCheckList: 'machines_are_linked_with_another_check_list',
  nameNotUnique: 'name_not_unique',
  notUnique: 'not_unique',
  activationFailed: 'activation_failed',
  tokenExpired: 'token_expired',
  machinesAreLinkedWithAnotherCatalog: 'machines_are_linked_with_another_catalog',
  entityNotFound: 'entity_not_found',
  lineNotExist: 'line_not_exist',
  valueTooLong: 'value_too_long',
  userIsNotMechanic: 'user_is_not_mechanic',
  keyIsDuplicated: 'key_is_duplicated',
  keyNotExist: 'key_not_exist',
  translationAlreadyExists: 'translation_already_exists',
  cannotUpdateDeletedUser: 'cannot_update_deleted_user',
  cannotGetDeletedUser: 'cannot_get_deleted_user',
  emailNotUnique: 'email_not_unique',
  incorrectEmail: 'incorrect_email',
  rfidNotUnique: 'rfid_not_unique',
  machineDoesNotExist: 'machines_does_not_exists',
  variantNotExists: 'variant_not_exists',
  machineNotExists: 'machine_not_exists',
  machineHasNotLinkedVariantConfiguration: 'machine_has_not_linked_variant_configuration',
  technologyNameCannotBeEmpty: 'technology_name_cannot_be_empty',
  technologyNameTooLong: 'technology_name_too_long',
  technologyNameNotUnique: 'technology_name_not_unique',
  noMatchingTechnologyComponentForVariant: 'no_matching_technology_component_for_variant',
  machineMustHaveAtLeastOneVariant: 'machine_must_have_at_least_one_variant',
  valueMustBeGreaterThanZero: 'value_must_be_greater_than_zero',
  valueGreaterThanMaxValue: 'value_greater_than_max_value',
  valueMustBeGreater: 'value_must_be_greater',
  valueOutOfRange: 'value_out_of_range',
  invalidInputData: 'invalid_input_data',
  cannotRemoveAssignedVariantWhenMachineWorking: 'Cannot_remove_assigned_variant_when_machine_working',
  retoolCannotBeStartedDuringRetool: 'retool_cannot_be_started_during_retool',
  cannotCreateRepairTaskFromNonActiveFailure: 'cannot_create_repair_task_from_non_active_failure',
  cannotAddMachine: 'cannot_add_machine',
  cannotRemoveOrderComponentMachine: 'cannot_remove_order_component_machine',
  retoolReasonShouldHaveValue: 'retool_reason_should_have_value',
  cannotRetoolToUnassignedVariant: 'cannot_retool_to_unassigned_variant'
} as const

// New error codes convention
export const ErrorCodes = {
  ENTITY_NOT_FOUND: 'entity_not_found',
  IS_REQUIRED: 'is_required',
  NOT_UNIQUE: 'not_unique',
  STRING_MAX_LENGHT: 'string_max_length',
  CANNOT_DECREASE_AMOUNT_BELOW_PRODUCED_COUNT: 'cannot_decrease_amount_below_produced_count',
  CANNOT_CREATE_ORDER_WITH_INCOMPLETE_TECHNOLOGY: 'cannot_create_order_with_incomplete_technology',
  CANNOT_CHANGE_START_DATE_OF_STARTED_ORDER: 'cannot_change_start_date_of_started_order',
  TECHNOLOGY_DOES_NOT_EXISTS: 'technology_does_not_exists',
  CANNOT_CHANGE_ORDER_TECHNOLOGY: 'cannot_change_order_technology',
  CANNOT_EDIT_ENDED_ORDER: 'cannot_edit_ended_order',
} as const

type ErrorCodesKeys = keyof typeof errorCodes
export type ErrorCode = (typeof errorCodes)[ErrorCodesKeys]

export const ErrorCodesTranslationKeys = {
  [errorCodes.keyIsDuplicated as string]: 'messages.theGivenKeysAreDuplicate',
  [errorCodes.keyNotExist as string]: 'messages.theGivenKeysShouldBeRemovedFromTheFile',
  [errorCodes.translationAlreadyExists as string]: 'messages.translationForPassedLanguageAlreadyExists',
  [errorCodes.machinesAreLinkedWithAnotherCheckList as string]: 'messages.machinesAreLinkedWithAnotherCheckList',
  [errorCodes.nameNotUnique as string]: 'messages.nameNotUnique',
  [errorCodes.notUnique as string]: 'messages.nameNotUnique',
  [errorCodes.activationFailed as string]: 'messages.activationFailed',
  [errorCodes.tokenExpired as string]: 'messages.tokenExpired',
  [errorCodes.machinesAreLinkedWithAnotherCatalog as string]: 'messages.machinesAreLinkedWithAnotherCatalog',
  [errorCodes.entityNotFound as string]: 'messages.entityNotFound',
  [errorCodes.valueTooLong as string]: 'messages.valueTooLong',
  [errorCodes.userIsNotMechanic as string]: 'messages.userIsNotMechanic',
  [errorCodes.cannotUpdateDeletedUser as string]: 'messages.cannotUpdateDeletedUser',
  [errorCodes.cannotGetDeletedUser as string]: 'messages.cannotGetDeletedUser',
  [errorCodes.machineDoesNotExist as string]: 'messages.selectedMachineIsRemoved',
  [errorCodes.emailNotUnique as string]: 'messages.duplicatedEmail',
  [errorCodes.incorrectEmail as string]: 'messages.incorrectEmail',
  [errorCodes.rfidNotUnique as string]: 'messages.duplicatedRfIdNumber',
  [errorCodes.lineNotExist as string]: 'messages.lineDoesNotExist',
  [errorCodes.variantNotExists as string]: 'messages.thereIsNoVariantWithName',
  [errorCodes.machineNotExists as string]: 'messages.machineNotExists',
  [errorCodes.machineHasNotLinkedVariantConfiguration as string]: 'messages.machineHasNotLinkedVariantConfiguration',
  [errorCodes.technologyNameCannotBeEmpty as string]: 'messages.technologyNameCannotBeEmpty',
  [errorCodes.technologyNameTooLong as string]: 'messages.technologyNameTooLong',
  [errorCodes.technologyNameNotUnique as string]: 'messages.technologyNameNotUnique',
  [errorCodes.noMatchingTechnologyComponentForVariant as string]: 'messages.noMatchingTechnologyComponentForVariant',
  [errorCodes.machineMustHaveAtLeastOneVariant as string]: 'messages.thisFieldIsRequired',
  [errorCodes.valueMustBeGreaterThanZero as string]: 'messages.valueMustBeGreaterThanZero',
  [errorCodes.valueGreaterThanMaxValue as string]: 'messages.valueMustBeGreaterThan',
  [errorCodes.valueMustBeGreater as string]: 'messages.valueMustBeGreaterThan',
  [errorCodes.valueOutOfRange as string]: 'messages.valueOutOfRange',
  [errorCodes.invalidInputData as string]: 'messages.invalidValue',
  [errorCodes.retoolCannotBeStartedDuringRetool as string]: 'messages.retoolCannotBeStartedDuringRetool',
  [errorCodes.cannotCreateRepairTaskFromNonActiveFailure as string]:
    'messages.cannotCreateRepairTaskFromNonActiveFailure',
  [errorCodes.cannotAddMachine as string]: 'messages.cannotAddWorkstation',
  [errorCodes.cannotRemoveOrderComponentMachine as string]: 'messages.cannotRemoveMachineFromOrderComponent',
  [errorCodes.retoolReasonShouldHaveValue as string]:
    'messages.enterTheReasonForStoppingWorkOnTheOrderToBeAbleToRetool',
  [errorCodes.cannotRetoolToUnassignedVariant as string]: 'messages.cannotRetoolToUnassignedVariant',
  // New error codes convention
  [ErrorCodes.ENTITY_NOT_FOUND as string]: 'messages.entityNotFound',
  [ErrorCodes.IS_REQUIRED as string]: 'messages.thisFieldIsRequired',
  [ErrorCodes.NOT_UNIQUE as string]: 'messages.nameNotUnique',
  [ErrorCodes.STRING_MAX_LENGHT as string]: 'messages.valueTooLong',
  [ErrorCodes.CANNOT_DECREASE_AMOUNT_BELOW_PRODUCED_COUNT as string]:
    'messages.cannotSetValueBelowTheNumberOfAlreadyProduced',
  [ErrorCodes.CANNOT_CREATE_ORDER_WITH_INCOMPLETE_TECHNOLOGY as string]: 'messages.selectATechnologyThatCanBeCompleted',
  [ErrorCodes.CANNOT_CHANGE_START_DATE_OF_STARTED_ORDER as string]: 'messages.youCannotEditTheStartDateOfAStartedOrder',
  [ErrorCodes.TECHNOLOGY_DOES_NOT_EXISTS as string]: 'messages.technologyDoesNotExist',
  [ErrorCodes.CANNOT_CHANGE_ORDER_TECHNOLOGY as string]: 'messages.youCannotChangeTheTechnologyOfAnExistingOrder',
  [ErrorCodes.CANNOT_EDIT_ENDED_ORDER as string]: 'messages.youCannotEditAnEndedOrder',
} as const

export const errorTypes = {
  UnauthorizedAccessException: 'UnauthorizedAccessException',
  ValidationException: 'ValidationException',
  AppException: 'AppException',
  NotFoundException: 'NotFoundException',
  BusinessRuleValidationException: 'BusinessRuleValidationException',
}

export default errors
