import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Alert } from '@mui/material'

import { Bold } from 'Styles/utils'

const EndedOrderAlert = ({ onClose }: { onClose?: () => void }) => {
  const { t } = useTranslation()

  return (
    <Alert severity='warning' onClose={onClose} sx={{ width: 512, boxShadow: 3 }}>
      {t('messages.thePlannerHasEndedWorkOnTheComponentAtYourWorkstation')}{' '}
      <Trans t={t} i18nKey='messages.retoolAsSoonAsPossible'>
        <Bold>Retool</Bold> as soon as possible.
      </Trans>
    </Alert>
  )
}

export default EndedOrderAlert
